import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/contactForm";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";

const SeoAuditPageDubai = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpSeoPage(title: { eq: "SEO Audit Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}

			jasonImg: wpMediaItem(title: { eq: "jasoncornes-image-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoImg: wpMediaItem(title: { eq: "seoaudit" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			seoTopImg: wpMediaItem(title: { eq: "Demystifying SEO presentation" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "SEO Audit",
				item: {
					url: `${siteUrl}/dubai/seo-audit`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/seo-audit`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Audit my website"
						button1Link="#book-now"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`SEO Audit \n<span class="text-secondary">Dubai</span>`}
						description="Supercharge your business growth with our comprehensive SEO audit."
					/>
				</section>
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={5}>
								<h2 className="display-5 text-primary mb-5">
									Unlock Your Website's True Potential with an SEO Audit
								</h2>
								<p>
									An SEO audit provides you with invaluable insights into your
									website's performance on Google. Discover how climbing the
									search rankings can drive unprecedented traffic and
									significantly boost your revenue.
								</p>
								<p>
									Our audit is your secret weapon for elevating your website's
									search engine rankings, increasing traffic, and skyrocketing
									sales and leads. Our expert team will pinpoint exactly where
									your site can be optimised to enhance Google's perception and
									craft a tailored plan to elevate your rankings. Secure your
									complimentary SEO audit today!
								</p>
							</Col>
							<Col lg={7}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.seoTopImg.gatsbyImage}
										alt={data.seoTopImg?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					style={{
						background:
							"transparent linear-gradient(58deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5 py-lg-9 "
				>
					<Container>
						<Row className="align-items-center">
							<Col lg={5} className="position-relative ps-6">
								<div
									style={{
										borderRadius: "15px",
										boxShadow: "0px 3px 30px #00000029",
										height: "30rem",
										overflow: "hidden",
									}}
									className="bg-white me-auto w-50"
								>
									<GatsbyImage
										className="w-100 "
										image={data.jasonImg.gatsbyImage}
										alt={data.jasonImg?.altText}
										objectPosition="top center"
									/>
								</div>

								<div
									style={{
										borderRadius: "15px",
										boxShadow: "0px 3px 30px #00000029",
										overflow: "hidden",
									}}
									className="bg-white position-absolute end-20 bottom-10 w-50"
								>
									<GatsbyImage
										className="w-100 "
										image={data.seoImg.gatsbyImage}
										alt={data.seoImg?.altText}
										objectPosition="top center"
									/>
								</div>
							</Col>
							<Col lg={7}>
								<p className="text-white mb-5">
									Claim your complimentary SEO audit and personalised
									consultation by entering your company’s details below. Our
									dedicated team will contact you promptly to arrange a
									discovery meeting, or you can select a convenient time via our
									online calendar.
								</p>
								<ContactForm extraButton />
							</Col>
						</Row>
					</Container>
				</section>

				<section id="what-it-covers" className="py-5 py-lg-7 bg-light-grey">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 text-primary ssp-bold mb-7">
									COMPLIMENTARY SEO AUDIT FOR BUSINESSES IN DUBAI
								</h2>
							</Col>
						</Row>
						<Row className="g-lg-6 g-5">
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-1.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className="ssp-bold text-primary my-3">Keyword Mastery</h3>
								<p>
									Our experts conduct a thorough keyword analysis, assessing
									search volumes to pinpoint the most potent keywords for your
									business to target.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-2.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className="ssp-bold text-primary my-3">
									Page Speed Optimisation
								</h3>
								<p>
									A sluggish website can cripple your rankings. Our audit
									meticulously examines your site's load times and evaluates how
									this impacts user experience.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-3.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className="ssp-bold text-primary my-3">
									Backlink Fortification
								</h3>
								<p>
									Poor-quality backlinks can tarnish your website's reputation.
									Our team evaluates your backlink profile to identify and
									eliminate harmful links that could be penalising your site.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-4.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className="ssp-bold text-primary my-3">
									Competitor Domination
								</h3>
								<p>
									Discover how your competitors harness SEO to attract traffic
									and identify strategic opportunities to surpass them in search
									rankings.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-5.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className="ssp-bold text-primary my-3">
									Technical SEO Enhancement
								</h3>
								<p>
									Optimising back-end elements like Schema markup can
									dramatically improve your website's visibility on search
									engines. We ensure your technical SEO is top-notch for maximum
									impact.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-6.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className="ssp-bold text-primary my-3">
									Data-Driven Reporting & Analytics
								</h3>
								<p>
									We scrutinise your website analytics to provide a clear
									picture of your current rankings and uncover opportunities for
									growth.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-7.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className="ssp-bold text-primary my-3">
									Strategic Information Architecture
								</h3>
								<p>
									We assess the structure of your website and landing pages to
									ensure they adhere to Google’s best practices, enhancing both
									user experience and search engine friendliness.
								</p>
							</Col>
							<Col md={6} lg={4}>
								<div
									className="d-flex align-items-end"
									style={{ height: "66px" }}
								>
									<StaticImage
										src="../../images/SEO-audit-Icon-8.svg"
										placeholder="blurred"
										quality={100}
										style={{ maxWidth: "50px" }}
									/>
								</div>
								<h3 className="ssp-bold text-primary my-3">
									Content Marketing Strategy
								</h3>
								<p>
									Content is the cornerstone of SEO success. We identify content
									gaps and ensure your material is optimised to prevent it from
									cannibalising your own rankings.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="book-now" className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 text-primary mb-5">
									Reserve Your Complimentary SEO Audit Today
								</h2>
								<p>
									Enter your details below, and we’ll send you your personalised
									report via email. Don’t miss the opportunity to book a free
									consultation with our team to discuss our tailored
									recommendations for your business.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/seo-audit?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/seo-audit?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>

				<div id="faq">
					<Faq page="SEO Audit" />
				</div>
				{/* <OSSection
					dubai
					link3="/dubai/web-design"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/> */}
			</Layout>
		</>
	);
};

export default SeoAuditPageDubai;
